/* eslint-disable default-case */
import produce from 'immer';
import {
  transformRequest, gpsRound, filterBySearch, updateChildElementSelect,
} from 'utils/helpers';
import mapStyleEmptyJSON from 'assets/mapstyles/style_osrd_empty.json';
import history from 'main/history';
import {
  midiObjects, MAP_URL, MAP_MODES, midiObjectsGroups, MAP_TRACK_SOURCES,
} from 'common/Map/const';
import { post, get } from 'common/requests';
import {
  set, unset, pullAt, dropRight, last, get as getObj,
} from 'lodash';

// Action Types
export const UPDATE_VIEWPORT = 'map/UPDATE_VIEWPORT';
export const UPDATE_TRANSFORM_REQUEST = 'map/UPDATE_TRANSFORM_REQUEST';
export const UPDATE_MAPSTYLE = 'map/UPDATE_MAPSTYLE';
export const UPDATE_FEATURE_INFO_HOVER = 'map/UPDATE_FEATURE_INFO_HOVER';
export const UPDATE_FEATURE_INFO_CLICK = 'map/UPDATE_FEATURE_INFO_CLICK';
export const TOGGLE_FILTERS = 'map/TOGGLE_FILTERS';
export const TOGGLE_OBJECTS = 'map/TOGGLE_OBJECTS';
export const TOGGLE_TRACKSOURCE = 'map/TOGGLE_TRACKSOURCE';
export const TOGGLE_SEARCHLOADING = 'map/TOGGLE_SEARCHLOADING';
export const UPDATE_LAYER_PROPERTY = 'map/UPDATE_LAYER_PROPERTY';
export const UPDATE_ELEMENTS_LIST = 'map/UPDATE_ELEMENTS_LIST';
export const ADD_SELECTED_ELEMENTS = 'map/ADD_SELECTED_ELEMENTS';
export const REMOVE_SELECTED_ELEMENTS = 'map/REMOVE_SELECTED_ELEMENTS';
export const SELECT_CHILD_ELEMENT = 'map/SELECT_CHILD_ELEMENT';
export const UNSELECT_CHILD_ELEMENT = 'map/UNSELECT_CHILD_ELEMENT';
export const UPDATE_SEARCH_OBJECTS = 'map/UPDATE_SEARCH_OBJECTS';
export const UPDATE_MODE = 'map/UPDATE_MODE';
export const UPDATE_SEARCH = 'map/UPDATE_SEARCH';
export const UPDATE_SEARCH_RESULTS = 'map/UPDATE_SEARCH_RESULTS';
export const UPDATE_POPUP_CONTENT = 'map/UPDATE_POPUP_CONTENT';
export const UPDATE_POPUP_CONTENT_PROPERTY = 'map/UPDATE_POPUP_CONTENT_PROPERTY';
export const DELETE_POPUP_CONTENT_PROPERTY = 'map/DELETE_POPUP_CONTENT_PROPERTY';
export const UPDATE_DRAG_AND_DROP = 'map/UPDATE_DRAG_AND_DROP';
export const UPDATE_USER_PREFERENCE = 'map/UPDATE_USER_PREFERENCE';
export const UPDATE_SELECTED_ZONE = 'map/UPDATE_SELECTED_ZONE';
export const DELETE_OBJECT = 'map/DELETE_OBJECT';
export const UPDATE_ERROR = 'map/UPDATE_ERROR';
export const UPDATE_SIDEBAR_TAB = 'map/SIDEBAR_TAB';
// Create objects
export const UPDATE_SELECTED_OBJECT_TO_CREATE = 'map/UPDATE_SELECTED_OBJECT_TO_CREATE';
export const UPDATE_OBJECT_TO_CREATE = 'map/UPDATE_OBJECT_TO_CREATE';
export const UPDATE_SELECTED_SOURCE_OBJECT = 'map/UPDATE_SELECTED_SOURCE_OBJECT';
export const UPDATE_VALIDATE_SIGNAL = 'map/UPDATE_VALIDATE_SIGNAL';
export const UPDATE_VALIDATE_BRANCHE = 'map/UPDATE_VALIDATE_BRANCHE';
// Modify objects
export const UPDATE_SELECTED_PROPERTY = 'map/UPDATE_SELECTED_PROPERTY';

const initElements = (initialObjects = [], initialGroups = []) => ({
  objects: initialObjects,
  groups: initialGroups,
});

// Reducer
export const initialState = {
  ref: undefined,
  mode: MAP_MODES.display,
  url: MAP_URL,
  mapStyle: mapStyleEmptyJSON,
  trackSource: MAP_TRACK_SOURCES.lineGeographic,
  search: '',
  searchResults: [],
  isSearchLoading: false,
  viewport: {
    latitude: 46.88731499073388,
    longitude: 2.5682289198122756,
    zoom: 5.6650,
    bearing: 0,
    pitch: 0,
    transformRequest: (url, resourceType) => transformRequest(url, resourceType, MAP_URL),
  },
  featureInfoHoverID: undefined,
  featureInfoClickID: undefined,
  featureSource: undefined,
  filters: {
    shown: false,
    osm: false,
  },
  elements: {
    search: '',
    shown: false,
    all: initElements(),
    selected: initElements(),
    filtered: initElements(),
  },
  layers: {
    vs: {
      visible: true,
    },
    vp: {
      visible: true,
    },
    communes: {
      visible: false,
    },
  },
  // Popup
  selectedObjectLayer: undefined,
  popupContent: {},
  newRows: {},
  dragAndDrop: false,
  userPreference: undefined,

  // Displayed zone
  selectedZone: undefined,

  // Create objects
  selectedObjectToCreate: undefined,
  objectToCreate: {},
  selectedSourceObject: {},
  validateSignal: false,
  validateBranche: false,

  // Modify objects
  selectedProperty: undefined,

  error: null,
  sidebarTab: '#geom',
};

export default function reducer(state = initialState, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case UPDATE_VIEWPORT:
        draft.viewport.width = action.viewport.width;
        draft.viewport.height = action.viewport.height;
        draft.viewport.latitude = action.viewport.latitude;
        draft.viewport.longitude = action.viewport.longitude;
        draft.viewport.zoom = action.viewport.zoom;
        draft.viewport.bearing = action.viewport.bearing;
        draft.viewport.pitch = action.viewport.pitch;
        draft.viewport.transitionDuration = action.viewport.transitionDuration;
        draft.viewport.transitionInterpolator = action.viewport.transitionInterpolator;
        break;
      case UPDATE_TRANSFORM_REQUEST:
        draft.viewport.transformRequest = (url, resourceType) => (
          transformRequest(url, resourceType, MAP_URL)
        );
        break;
      case UPDATE_MAPSTYLE:
        draft.mapStyle = action.mapStyle;
        draft.filters[action.styleName] = !state.filters[action.styleName];
        break;
      case UPDATE_FEATURE_INFO_HOVER:
        draft.featureSource = action.featureSource;
        draft.featureInfoHoverID = action.featureInfoHoverID;
        break;
      case UPDATE_FEATURE_INFO_CLICK:
        draft.featureInfoClickID = action.featureInfoClickID;
        break;
      case TOGGLE_FILTERS:
        draft.elements.shown = false;
        draft.filters.shown = !state.filters.shown;
        break;
      case TOGGLE_OBJECTS:
        draft.filters.shown = false;
        draft.elements.shown = !state.elements.shown;
        break;
      case TOGGLE_TRACKSOURCE:
        draft.trackSource = action.trackSource;
        break;
      case UPDATE_LAYER_PROPERTY:
        draft.layers[action.layer][action.property] = action.value;
        break;
      case UPDATE_ELEMENTS_LIST:
        draft.elements.all = action.elements;
        break;
      case ADD_SELECTED_ELEMENTS:
        if (!draft.elements.selected[action.elementType].find((el) => el.key === action.element.key)) {
          draft.elements.selected[action.elementType].push(action.element);
        }
        break;
      case REMOVE_SELECTED_ELEMENTS:
        draft.elements.selected[action.elementType] = draft.elements.selected[action.elementType]
          .filter((el) => el.key !== action.element.key);
        break;
      case SELECT_CHILD_ELEMENT:
        updateChildElementSelect(draft, action, true);
        break;
      case UNSELECT_CHILD_ELEMENT:
        updateChildElementSelect(draft, action, false);
        break;
      case UPDATE_SEARCH_OBJECTS:
        draft.elements.search = action.search;
        // For each element type, we filter each array with the search word
        Object.keys(draft.elements.filtered).forEach((elementType) => {
          draft.elements.filtered[elementType] = filterBySearch(
            draft.elements.all[elementType],
            action.search,
            'label',
          );
        });
        break;
      case UPDATE_POPUP_CONTENT:
        draft.popupContent = action.popupContent;
        console.log(action.popupContent);
        if (action.selectedObjectLayer !== undefined) {
          draft.selectedObjectLayer = action.selectedObjectLayer;
        }
        break;
      case UPDATE_POPUP_CONTENT_PROPERTY:
        set(draft.popupContent, action.path, action.value);
        break;
      case DELETE_POPUP_CONTENT_PROPERTY:
        if (Array.isArray(getObj(draft.popupContent, dropRight(action.path)))) {
          pullAt(getObj(draft.popupContent, dropRight(action.path)), last(action.path));
        } else {
          unset(draft.popupContent, action.path);
        }
        break;
      case UPDATE_MODE:
        draft.mode = action.mode;
        break;
      case UPDATE_SEARCH:
        draft.search = action.search;
        break;
      case UPDATE_SEARCH_RESULTS:
        draft.searchResults = action.results;
        break;
      case TOGGLE_SEARCHLOADING:
        draft.isSearchLoading = action.isSearchLoading;
        break;
      case UPDATE_DRAG_AND_DROP:
        draft.dragAndDrop = action.dragAndDrop;
        break;
      case UPDATE_USER_PREFERENCE:
        draft.userPreference = action.userPreference;
        break;
      case UPDATE_SELECTED_ZONE:
        draft.selectedZone = action.zone;
        break;
      case UPDATE_SELECTED_OBJECT_TO_CREATE:
        draft.selectedObjectToCreate = action.selectedObjectToCreate;
        break;
      case UPDATE_OBJECT_TO_CREATE:
        draft.objectToCreate = action.objectToCreate;
        break;
      case UPDATE_SELECTED_SOURCE_OBJECT:
        draft.selectedSourceObject = action.selectedSourceObject;
        break;
      case UPDATE_VALIDATE_SIGNAL:
        draft.validateSignal = action.validateSignal;
        break;
      case UPDATE_VALIDATE_BRANCHE:
        draft.validateBranche = action.validateBranche;
        break;
      case UPDATE_SELECTED_PROPERTY:
        draft.selectedProperty = action.selectedProperty;
        break;
      case UPDATE_ERROR:
        draft.error = action.error;
        break;
      case UPDATE_SIDEBAR_TAB:
        draft.sidebarTab = action.sidebarTab;
        break;
    }
  });
}

// Action Creators
function updateViewportAction(viewport) {
  return {
    type: UPDATE_VIEWPORT,
    viewport,
  };
}

export function addSelectedElement(element, elementType) {
  return {
    type: ADD_SELECTED_ELEMENTS,
    element,
    elementType,
  };
}

function removeSelectedElement(element, elementType) {
  return {
    type: REMOVE_SELECTED_ELEMENTS,
    element,
    elementType,
  };
}

function selectChildElement(parent, child, elementType) {
  return {
    type: SELECT_CHILD_ELEMENT,
    parent,
    child,
    elementType,
  };
}

function unselectChildElement(parent, child, elementType) {
  return {
    type: UNSELECT_CHILD_ELEMENT,
    parent,
    child,
    elementType,
  };
}

function updateSearchResultsAction(results) {
  return {
    type: UPDATE_SEARCH_RESULTS,
    results,
  };
}

function toggleSearchLoadingAction(isSearchLoading) {
  return {
    type: TOGGLE_SEARCHLOADING,
    isSearchLoading,
  };
}

export function updateSelectedProperty(selectedProperty) {
  return {
    type: UPDATE_SELECTED_PROPERTY,
    selectedProperty,
  };
}

// Functions
export function updateViewport(viewport, baseUrl = undefined, updateRouter = true) {
  return (dispatch) => {
    dispatch(updateViewportAction(viewport));
    if (baseUrl !== undefined && updateRouter) {
      history.push(`${baseUrl}/${gpsRound(viewport.latitude)}/${gpsRound(viewport.longitude)}/${gpsRound(viewport.zoom)}/${gpsRound(viewport.bearing)}/${gpsRound(viewport.pitch)}`);
    }
  };
}

export function updateMapStyle(mapStyle, styleName) {
  return (dispatch) => {
    dispatch({
      type: UPDATE_MAPSTYLE,
      mapStyle,
      styleName,
    });
  };
}

export function updateFeatureInfoHover(featureInfoHoverID, featureSource) {
  return (dispatch) => {
    dispatch({
      type: UPDATE_FEATURE_INFO_HOVER,
      featureInfoHoverID,
      featureSource,
    });
  };
}

export function updateFeatureInfoClick(featureInfoClickID, featureSource) {
  return (dispatch) => {
    dispatch({
      type: UPDATE_FEATURE_INFO_CLICK,
      featureInfoClickID,
      featureSource,
    });
  };
}

export function toggleFilters() {
  return (dispatch) => {
    dispatch({
      type: TOGGLE_FILTERS,
    });
  };
}

export function toggleObjects() {
  return (dispatch) => {
    dispatch({
      type: TOGGLE_OBJECTS,
    });
  };
}

export function toggleTrackSource(trackSource) {
  return (dispatch) => {
    dispatch({
      type: TOGGLE_TRACKSOURCE,
      trackSource,
    });
  };
}

export function updateError(error, errorMessage) {
  console.log(errorMessage);
  if (error !== null) { console.log(error.response.status, error.response.data); }
  return (dispatch) => {
    dispatch({
      type: UPDATE_ERROR,
      error,
    });
  };
}

export function updateSelectedElement(element, elementType, isSelected) {
  return (dispatch) => {
    if (isSelected) {
      dispatch(addSelectedElement(element, elementType));
    } else {
      dispatch(removeSelectedElement(element, elementType));
    }
  };
}

export function updateSelectedChildElement(parent, child, elementType, isSelected) {
  return (dispatch) => {
    if (isSelected) {
      dispatch(selectChildElement(parent, child, elementType));
    } else {
      dispatch(unselectChildElement(parent, child, elementType));
    }
  };
}

export function updateLayerProperty(layer, property, value) {
  return (dispatch) => {
    dispatch({
      type: UPDATE_LAYER_PROPERTY,
      layer,
      property,
      value,
    });
  };
}

export function updateSearchObjects(search) {
  return (dispatch) => {
    dispatch({
      type: UPDATE_SEARCH_OBJECTS,
      search,
    });
  };
}

export function updateMode(mode) {
  return (dispatch) => {
    dispatch({
      type: UPDATE_MODE,
      mode,
    });
  };
}

export function updateSearch(search) {
  return async (dispatch) => {
    dispatch({
      type: UPDATE_SEARCH,
      search,
    });

    if (search === '') {
      dispatch(updateSearchResultsAction([]));
    }
  };
}

export function updateSearchResults() {
  return async (dispatch, getState) => {
    const {
      map: {
        elements,
        search,
      },
    } = getState();
    let pctypes = '';
    const params = {
      search: `%${search}%`,
      table: 'midi',
    };
    const nbSelectedObjects = elements.selected.objects.length;
    if (nbSelectedObjects !== 0) {
      elements.selected.objects.forEach((el, i) => {
        pctypes += `${el.pcid}${i !== nbSelectedObjects - 1 ? ',' : ''}`;
      });
      params.pcid = pctypes;
    }
    try {
      dispatch(toggleSearchLoadingAction(true));
      const { results } = await get('/search/map', params);
      dispatch(updateSearchResultsAction(results));
      dispatch(toggleSearchLoadingAction(false));
    } catch (e) {
      console.log(e);
    }
  };
}

export function updatePopupContentProperty(path, value) {
  return (dispatch) => {
    dispatch({
      type: UPDATE_POPUP_CONTENT_PROPERTY,
      path,
      value,
    });
  };
}

export function deletePopupContentProperty(path) {
  return (dispatch) => {
    dispatch({
      type: DELETE_POPUP_CONTENT_PROPERTY,
      path,
    });
  };
}

export function updatePopupContent(popupContent, selectedObjectLayer) {
  return (dispatch) => {
    dispatch({
      type: UPDATE_POPUP_CONTENT,
      popupContent,
      selectedObjectLayer,
    });
  };
}

export function updateDragAndDrop(dragAndDrop) {
  return (dispatch) => {
    dispatch({
      type: UPDATE_DRAG_AND_DROP,
      dragAndDrop,
    });
  };
}

export function updateUserPreference(userPreference) {
  return (dispatch) => {
    dispatch({
      type: UPDATE_USER_PREFERENCE,
      userPreference,
    });
  };
}

export function updateSelectedZone(zone) {
  return (dispatch) => {
    dispatch({
      type: UPDATE_SELECTED_ZONE,
      zone,
    });
  };
}

export function updateSelectedObjectToCreate(selectedObjectToCreate) {
  return (dispatch) => {
    dispatch({
      type: UPDATE_SELECTED_OBJECT_TO_CREATE,
      selectedObjectToCreate,
    });
  };
}

export function updateObjectToCreate(objectToCreate) {
  return (dispatch) => {
    dispatch({
      type: UPDATE_OBJECT_TO_CREATE,
      objectToCreate,
    });
  };
}

export function updateSelectedSourceObject(selectedSourceObject) {
  return (dispatch) => {
    dispatch({
      type: UPDATE_SELECTED_SOURCE_OBJECT,
      selectedSourceObject,
    });
  };
}

export function updateValidateSignal(validateSignal) {
  return (dispatch) => {
    dispatch({
      type: UPDATE_VALIDATE_SIGNAL,
      validateSignal,
    });
  };
}

export function updateValidateBranche(validateBranche) {
  return (dispatch) => {
    dispatch({
      type: UPDATE_VALIDATE_BRANCHE,
      validateBranche,
    });
  };
}

export function updateObject(modification) {
  return async (dispatch) => {
    try {
      const updatedObject = await post('/midi/modification/', modification);
      console.log('updatedObject', updatedObject);
      dispatch(updatePopupContent(updatedObject.new_gaia_object));
      return updatedObject;
    } catch (err) {
      console.log(err.response.status, err.response.data);
      dispatch(updateError(err, 'update Error'));
    }
  };
}

export function createObject(modification) {
  return async (dispatch) => {
    try {
      const createdObject = await post('/midi/modification/', modification);
      console.log(createdObject);
      return createdObject;
    } catch (err) {
      dispatch(updateError(err, 'Object Creation Error'));
      return undefined;
    }
  };
}

export function deleteObjects(modification) {
  return async (dispatch) => {
    try {
      const res = await post('/midi/modification/', modification);
      dispatch({
        type: DELETE_OBJECT,
        modification,
      });
      console.log(res);
      return res;
    } catch (err) {
      dispatch(updateError(err, 'Object Deletion Error'));
    }
  };
}

export const updateElementsList = (objects, groups) => (dispatch) => {
  dispatch({
    type: UPDATE_ELEMENTS_LIST,
    elements: initElements(objects.filter((o) => !o.hide), groups),
  });
}

export function updateSideBarTab(sidebarTab) {
  return (dispatch) => {
    dispatch({
      type: UPDATE_SIDEBAR_TAB,
      sidebarTab,
    });
  };
}
