import { DATA_TYPES } from 'common/types';
import { updateSelectedEvent } from 'utils/reducerHelpers';
import i18n from '../../../i18n';

const invalidDateFormatter = (i, p, data) => (
  data === 'Invalid date'
    ? i18n.t('Data.errorMessage.invalidDate')
    : data
);


export const EVENTS_TABLE_HEADERS = [
  {
    key: 'idBrehatJour',
    label: 'Data.tableHeaders.brehatIncidentNumber',
    data: [
      {
        type: DATA_TYPES.string,
        path: ['properties', 'id_brehat_jour'],
      },
    ],
  },
  {
    key: 'dateHeureDebut',
    label: 'Data.tableHeaders.beginDatetime',
    data: [{
      type: DATA_TYPES.date,
      format: 'Do MMMM YYYY, HH:mm',
      path: ['properties', 'date_heure_debut'],
    }],
  },
  {
    key: 'pr_debut',
    label: 'Data.tableHeaders.pr',
    data: [
      {
        type: DATA_TYPES.string,
        path: ['properties', 'pr_debut', 'LIB_CI'],
      },
      {
        type: DATA_TYPES.string,
        path: ['properties', 'pr_debut', 'LIB_CH'],
      },
    ],
    spliter: '/',
    onClick: updateSelectedEvent,
  },
  {
    key: 'eventType',
    label: 'Data.tableHeaders.eventType',
    data: [{
      type: DATA_TYPES.string,
      path: ['properties', 'incident_type', 'libelle'],
    }],
  },
  {
    key: 'failureType',
    label: 'Data.tableHeaders.failureType',
    data: [{
      type: DATA_TYPES.string,
      path: ['properties', 'failure_type', 'libelle'],
    }],
  },
  {
    key: 'failingResource',
    label: 'Data.tableHeaders.failingResource',
    data: [{
      type: DATA_TYPES.string,
      path: ['properties', 'ressource_type', 'libelle'],
    }],
  },
  {
    key: 'lowerTime',
    label: 'Data.tableHeaders.lowerTime',
    data: [{
      type: DATA_TYPES.date,
      format: 'Do MMMM YYYY, HH:mm',
      path: ['properties', 'prediction', 'lower_date_heure_predict'],
      formatter: invalidDateFormatter,
    }],
  },
  {
    key: 'mediumTime',
    label: 'Data.tableHeaders.mediumTime',
    data: [{
      type: DATA_TYPES.date,
      format: 'Do MMMM YYYY, HH:mm',
      path: ['properties', 'prediction', 'median_date_heure_predict'],
      formatter: invalidDateFormatter,
    }],
  },
  {
    key: 'upperTime',
    label: 'Data.tableHeaders.upperTime',
    data: [{
      type: DATA_TYPES.date,
      format: 'Do MMMM YYYY, HH:mm',
      path: ['properties', 'prediction', 'upper_date_heure_predict'],
      formatter: invalidDateFormatter,
    }],
  },
];

export const STATS_TABLE_HEADERS = [];
