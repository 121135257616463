import React from 'react';
import { Source, Layer } from 'react-map-gl';
import PropTypes from 'prop-types';

import Hover from 'common/Map/Hover';
import { MAP_URL } from 'common/Map/const';
import { getPhritObjectByKey, PHRIT_OBJECTS_KEYS } from 'applications/phrit/consts/objects';

const PR_PROPS = getPhritObjectByKey(PHRIT_OBJECTS_KEYS.pr);

const DISPLAY_PROPS = {
  type: 'circle',
  paint: {
    'circle-color': '#4d4f53',
    'circle-radius': 3,
  },
};

const PR = (props) => {
  const { isVisible } = props;

  const url = `${MAP_URL}${PR_PROPS.uri}`;
  const sourceLayer = PR_PROPS.sourceTable;
  const layout = {
    visibility: isVisible ? 'visible' : 'none',
  };

  return (
    <Source
      id={`${sourceLayer}-source`}
      type="vector"
      url={url}
    >
      <Layer
        id={`${sourceLayer}-layer`}
        source-layer={sourceLayer}
        layout={layout}
        {...DISPLAY_PROPS}
      />
      <Hover sourceLayer={sourceLayer} type={DISPLAY_PROPS.type} />
    </Source>
  );
};

PR.propTypes = {
  isVisible: PropTypes.bool,
};

PR.defaultProps = {
  isVisible: false,
};

export default PR;
