export const PHRIT_OBJECTS_KEYS = {
  pr: 'point_remarquable',
  event: 'event',
  ligneGeo: 'ligneGeo',
};

export const PHRIT_OBJECTS_LAYERS = {
  pr: 'phrit_pointremarquable',
  ligneGeo: 'phrit_ligne',
  event: 'flux_evenementincidentbrehat',
};

export const PHRIT_OBJECTS = [
  {
    key: PHRIT_OBJECTS_KEYS.pr,
    sourceTable: PHRIT_OBJECTS_LAYERS.pr,
    label: 'Points remarquables',
    singular: 'Point remarquable',
    shortLabel: 'PR',
    uri: '/chartis/layer/phrit_pointremarquable/mvt/geo/',
  },
  {
    key: PHRIT_OBJECTS_KEYS.event,
    sourceTable: PHRIT_OBJECTS_LAYERS.event,
    label: 'Incidents',
    singular: 'Incident',
    shortLabel: 'Incident',
    uri: '/flux/tile/legacy/evenements/',
  },
  {
    key: PHRIT_OBJECTS_KEYS.ligneGeo,
    sourceTable: PHRIT_OBJECTS_LAYERS.ligneGeo,
    singular: 'Ligne',
    hide: true,
  },
];

export const getPhritObjectByKey = (key) => PHRIT_OBJECTS.find((o) => o.key === key);
