import React from 'react';
import { PopupInfosCustomContent, PopupInfosCustomTitle } from 'applications/phrit/components/PopupInfosCustom';
import { Popup as MapPopup } from 'react-map-gl';
import moment from 'moment';
import 'moment/locale/fr';

import { convertLayerVariables } from 'utils/helpers';
import PopupInfos from 'common/Map/Popup/PopupInfos';
import { PHRIT_OBJECTS_LAYERS } from 'applications/phrit/consts/objects';

const dateFormat = 'Do MMMM YYYY, HH:mm';
const exists = (el) => el && (el !== null) && (JSON.parse(el) !== null);

const formatProperties = (feature) => {
  const properties = convertLayerVariables(feature.properties);
  if (!properties.longueur) {
    properties.longueur = properties.pkLigneF - properties.pkLigneD;
  }

  const source = feature.sourceLayer || feature.source;

  switch (source) {
    case PHRIT_OBJECTS_LAYERS.pr:
      break;
    case PHRIT_OBJECTS_LAYERS.ligneGeo:
      properties.libelle = properties.code_ligne;
      break;
    case `${PHRIT_OBJECTS_LAYERS.event}-source`:
      if (properties.pr_debut) {
        const PRDebut = JSON.parse(properties.pr_debut);
        properties.libelle = PRDebut.LIB_CH === null ? PRDebut.LIB_CI : `${PRDebut.LIB_CI}/${PRDebut.LIB_CH}`;
      }
      if (exists(properties.incident_type)) {
        const TI = JSON.parse(properties.incident_type);
        properties.eventType = TI.libelle;
      }
      if (exists(properties.failure_type)) {
        const TD = JSON.parse(properties.failure_type);
        properties.failureType = TD.libelle;
      }
      if (exists(properties.ressource_type)) {
        const TR = JSON.parse(properties.ressource_type);
        properties.failingResource = TR.libelle;
      }
      if (exists(properties.prediction)) {
        const {
          lower_date_heure_predict: lowerPredTime,
          median_date_heure_predict: midPredTime,
          upper_date_heure_predict: upperPredTime,
        } = JSON.parse(properties.prediction);
        properties.lowerPredTime = moment(lowerPredTime).format(dateFormat);
        properties.midPredTime = moment(midPredTime).format(dateFormat);
        properties.upperPredTime = moment(upperPredTime).format(dateFormat);
      }
      break;
    default:
      break;
  }

  return properties;
};

const Popup = (e) => {
  const { featureInfoHover } = e;
  if (featureInfoHover && featureInfoHover.features) {
    const feature = featureInfoHover.features[0];

    const backgroundColor = feature.sourceLayer === PHRIT_OBJECTS_LAYERS.ligneGeo ? 'bg-primary' : 'bg-secondary';

    return (
      <MapPopup
        longitude={featureInfoHover.lngLat[0]}
        latitude={featureInfoHover.lngLat[1]}
        closeButton={false}
        className="mapboxgl-hover-custom-popup"
      >
        <PopupInfos
          title={<PopupInfosCustomTitle {...formatProperties(feature)} />}
          content={<PopupInfosCustomContent {...formatProperties(feature)} hideId />}
          backgroundColor={backgroundColor}
        />
      </MapPopup>
    );
  }
  return null;
};

export default Popup;
