import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { BsStar, BsStarFill } from 'react-icons/bs';
import { useTranslation } from 'react-i18next';
import nextId from 'react-id-generator';

import TableSNCF from 'common/BootstrapSNCF/TableSNCF';
import SelectSNCF from 'common/BootstrapSNCF/SelectSNCF';
import './Events.css';
import FeedbackModal from 'applications/phrit/components/FeedbackModal';
import {
  EVENT_PARAMS, getEICList, getEventsTable, getEventType, getFailureType, getFailingResource, updateSelectedEventId,
} from 'reducers/phrit/events';
import Pagination from 'common/BootstrapSNCF/PaginationSNCF';
import '../common.scss';
import InputSNCF from 'common/BootstrapSNCF/InputSNCF';
import { useDebouncedCallback } from 'use-debounce';

const INITIAL_SELECTED_PARAMS = {
  [EVENT_PARAMS.eventType]: undefined,
  [EVENT_PARAMS.failureType]: undefined,
  [EVENT_PARAMS.failingResource]: undefined,
  [EVENT_PARAMS.eic]: undefined,
  [EVENT_PARAMS.brehatId]: '',
};

const ALL_OPTION = {
  key: 'all',
  label: 'Tous',
};

const REQUEST_LIMIT = 20;

const Events = ({ tableHeaders, filters }) => {
  const feedbackModalId = 'feedback-modal';

  const { t } = useTranslation();

  const [selectedParams, setSelectedParams] = useState(INITIAL_SELECTED_PARAMS);
  const [activePage, setActivePage] = useState(1);
  const [firstPage, setFirstPage] = useState(0);
  const [showBody, setShowBody] = useState(false);
  const [offset, setOffset] = useState({ limit: REQUEST_LIMIT, offset: 0 });
  const [searchBrehatId, setSearchBrehatId] = useState(INITIAL_SELECTED_PARAMS[EVENT_PARAMS.brehatId]);
  const dispatch = useDispatch();
  const {
    itemsTable, eventType, failureType, failingResource, totalItems, eics,
  } = useSelector((state) => state.phrit.events);

  // To control useEffects
  const isFirstRun = useRef(true);

  const updateEvents = async (params, localOffset) => {
    setShowBody(false);
    await dispatch(getEventsTable({
      ...params,
      [EVENT_PARAMS.brehatId]: searchBrehatId,
    }, localOffset));
    setShowBody(true);
  };

  const debouncedEventsUpdate = useDebouncedCallback(
    async (params, localOffset) => {
      await updateEvents(params, localOffset);
    },
    500,
  );

  // Search debounce
  useEffect(() => {
    if (!isFirstRun.current) {
      debouncedEventsUpdate.callback(selectedParams, offset);
    }
  }, [searchBrehatId]);

  // Initial useEffect
  useEffect(() => {
    if (isFirstRun.current) isFirstRun.current = false;
    (async () => {
      try {
        dispatch(getEventType());
        dispatch(getFailureType());
        dispatch(getFailingResource());
        dispatch(getEICList());
      } catch (e) {
        console.log(e);
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      await updateEvents(selectedParams, offset);
    })();
    const interval = setInterval(() => {
      updateEvents(selectedParams, offset);
    }, 60000);
    return () => clearInterval(interval);
  }, [selectedParams, offset]);

  const eventTypeOptions = [ALL_OPTION, ...eventType];
  const failureTypeOptions = [ALL_OPTION, ...failureType];
  const failingResourceOptions = [ALL_OPTION, ...failingResource];
  const eicOptions = [ALL_OPTION, ...eics];

  const onSelectParam = (param, value) => {
    setShowBody(false);
    setActivePage(1);
    setFirstPage(0);
    setOffset({ limit: REQUEST_LIMIT, offset: 0 });
    setSelectedParams({
      ...selectedParams,
      [param]: value,
    });
  };

  const getActions = (eventId, eventStatus, eventLayerName, eventActionType, eventType, eventHasFeedback, itemPredictionId) => (
    <div className="cell-inner">
      <button
        className="btn btn-only-icon btn-transparent btn-color-gray"
        type="button"
        title={t('Data.events.feedbackButton')}
        data-target={`#${feedbackModalId}`}
        data-toggle="modal"
        onClick={() => dispatch(updateSelectedEventId(eventId, itemPredictionId))}
      >
        {eventHasFeedback
          ? <BsStarFill style={{ fill: 'rgb(255, 182, 18)' }} />
          : <BsStar style={{ fill: 'rgb(255, 182, 18)' }} />}
      </button>
    </div>
  );

  const getPageContent = (e, pages, paginationRange) => {
    const index = e.currentTarget.getAttribute('href').split('#')[1];
    const limitPages = paginationRange < pages ? paginationRange : pages;

    switch (index) {
      case 'first':
        setShowBody(false);
        setActivePage(1);
        setFirstPage(0);
        setOffset({ limit: REQUEST_LIMIT, offset: 0 });
        break;
      case 'last':
        setShowBody(false);
        setActivePage(pages);
        setFirstPage(pages - limitPages);
        setOffset({ limit: REQUEST_LIMIT, offset: (pages - 1) * REQUEST_LIMIT });

        break;
      case 'previous':
        setShowBody(false);
        setActivePage(activePage - 1);
        if (firstPage > 0) {
          setFirstPage(firstPage - 1);
        }
        setOffset({ limit: REQUEST_LIMIT, offset: (activePage - 2) * REQUEST_LIMIT });
        break;
      case 'next':
        setShowBody(false);
        setActivePage(activePage + 1);
        if (activePage + 1 === limitPages
          + firstPage && activePage + 1 < pages && limitPages !== pages) {
          setFirstPage(firstPage + 1);
        }
        setOffset({ limit: REQUEST_LIMIT, offset: activePage * REQUEST_LIMIT });
        break;
      default:
        setShowBody(false);
        setOffset({ limit: REQUEST_LIMIT, offset: (index - 1) * REQUEST_LIMIT });
        setActivePage(Number(index));
        if (Number(index) === limitPages
        + firstPage && Number(index) > activePage && Number(index) < pages) {
          setFirstPage(firstPage + 1);
        }
        if (firstPage > 0 && Number(index) < activePage) {
          setFirstPage(firstPage - 1);
        }
    }
  };

  const pages = totalItems % REQUEST_LIMIT !== 0 ? Math.floor(totalItems / REQUEST_LIMIT)
                + 1 : totalItems / REQUEST_LIMIT;

  return (
    <>
      <div className="row my-3 pl-2">
        <div className="col-3 pr-3">
          <SelectSNCF
            id={`select-params-${nextId()}`}
            title={t('Data.events.eventTypeTitle')}
            options={eventTypeOptions}
            selectedValue={selectedParams[EVENT_PARAMS.eventType] || ALL_OPTION}
            onChange={(e) => onSelectParam(EVENT_PARAMS.eventType, e.currentTarget.value)}
            selectStyle="cell-inner pr-6"
            labelKey="label"
          />
        </div>
        <div className="col-3 pr-3">
          <SelectSNCF
            id={`select-params-${nextId()}`}
            title={t('Data.events.failureTypeTitle')}
            options={failureTypeOptions}
            selectedValue={selectedParams[EVENT_PARAMS.failureType] || ALL_OPTION}
            onChange={(e) => onSelectParam(EVENT_PARAMS.failureType, e.currentTarget.value)}
            selectStyle="cell-inner  pr-6"
            labelKey="label"
          />
        </div>
        <div className="col-3 pr-3">
          <SelectSNCF
            id={`select-params-${nextId()}`}
            title={t('Data.events.failingRessource')}
            options={failingResourceOptions}
            selectedValue={selectedParams[EVENT_PARAMS.failingResource] || ALL_OPTION}
            onChange={(e) => onSelectParam(EVENT_PARAMS.failingResource, e.currentTarget.value)}
            selectStyle="cell-inner pr-6"
            labelKey="label"
          />
        </div>
        <div className="col-3 pr-3">
          <SelectSNCF
            id={`select-params-${nextId()}`}
            title={t('Data.events.eic')}
            options={eicOptions}
            selectedValue={selectedParams[EVENT_PARAMS.eic] || ALL_OPTION}
            onChange={(e) => onSelectParam(EVENT_PARAMS.eic, e.currentTarget.value)}
            selectStyle="cell-inner pr-6"
            labelKey="label"
          />
        </div>
        {filters}
      </div>
      <div className="row my-3 pl-2">
        <div className="col-3 pr-3">
          <InputSNCF
            type="number"
            id="brehat-id-input-search"
            onChange={(e) => setSearchBrehatId(e.target.value)}
            label="Numéro incident Brehat"
            value={searchBrehatId}
            placeholder="Rechercher"
            onClear={() => setSearchBrehatId('')}
            clearButton
            noMargin
          />
        </div>
      </div>
      <div className="row" style={{ overflow: 'auto', maxHeight: '75vh' }}>

        {totalItems === 0
          && (
          <div className="alert-no-results">
            <h2 className="text-white mb-0">{t('Data.events.alertNoResult')}</h2>
          </div>
          )}
        <TableSNCF
          heads={tableHeaders}
          content={itemsTable}
          actions={getActions}
          fixedActions={false}
          cssExtra="vh-55 fixHeader"
          showBody={showBody}
        />
      </div>
      <Pagination
        pages={pages}
        activePage={activePage}
        firstPage={firstPage}
        getPageContent={getPageContent}
        centered
      />

      <FeedbackModal
        modalId={feedbackModalId}
        selectedParams={selectedParams}
        offset={offset}

      />
    </>
  );
};

Events.propTypes = {
  tableHeaders: PropTypes.array.isRequired,
  filters: PropTypes.object,
};

Events.defaultProps = {
  filters: <></>,
};

export default Events;
