import React from 'react';
import { useTranslation } from 'react-i18next';

import { EVENTS_TABLE_HEADERS, STATS_TABLE_HEADERS } from 'applications/phrit/views/tableHeaders';
import Events from 'applications/phrit/views/Data/Events';
import Stats from 'applications/phrit/views/Data/Stats';

const Data = () => {
  const { t } = useTranslation();

  return (
    <>
      <div className="actionbar has-tabs">
        <div className="actionbar-head">
          <h1 className="mb-0">{t('Data.title')}</h1>
        </div>
        <nav role="navigation" className="position-relative mt-2">
          <ul className="nav navtabs mb-0 dragscroll">
            <li className="navtabs-item pr-4">
              <a href="#events" className="active" role="tab" data-toggle="tab">
                {t('Data.events.title')}
              </a>
            </li>
            <li className="navtabs-item pr-4">
              <a href="#stats" data-toggle="tab">
                {t('Data.statsTabTitle')}
              </a>
            </li>
          </ul>
        </nav>
      </div>
      <main role="main" className="mastcontainer bg-white">
        <div className="tab-content p-3">
          <div className="tab-pane fade show active" id="events" role="tabpanel">
            <Events
              tableHeaders={EVENTS_TABLE_HEADERS}
              pathName={window.location.pathname}
              htmlId="events-search"
            />
          </div>
          <div className="tab-pane fade" id="stats" role="tabpanel">
            <Stats
              tableHeaders={STATS_TABLE_HEADERS}
              pathName={window.location.pathname}
              htmlId="stats-search"
            />
          </div>
        </div>
      </main>
    </>
  );
};

export default (Data);
