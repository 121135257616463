import { persistCombineReducers } from 'redux-persist';
import createCompressor from 'redux-persist-transform-compress';
import { createFilter } from 'redux-persist-transform-filter';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage

import mainReducer from './main';
import userReducer from './user';
import mapReducer from './map';
import zonesReducer from './zones';
import logsReducer from './logs';
import profileReducer from './profile';
import libraryReducer from './library';
import phritReducer from './phrit';


const compressor = createCompressor({
  whitelist: ['traincompo'],
});

const mapWhiteList = [
  'mapStyle',
  'trackSource',
  'layers',
  'userPreference',
  'filters',
];

const userWhiteList = [
  'account',
];

const saveMapFilter = createFilter(
  'map',
  mapWhiteList,
);

const saveUserFilter = createFilter(
  'user',
  userWhiteList,
);

const persistConfig = {
  key: 'root',
  storage,
  transforms: [compressor, saveMapFilter, saveUserFilter],
  whitelist: ['user', 'map', 'traincompo', 'hermes'],
};

const rootReducer = {
  user: userReducer,
  map: mapReducer,
  main: mainReducer,
  zones: zonesReducer,
  logs: logsReducer,
  profile: profileReducer,
  library: libraryReducer,
  phrit: phritReducer,
};

export default persistCombineReducers(persistConfig, rootReducer);
