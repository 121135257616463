import produce from 'immer';
import { get, post } from 'common/requests';
import { normalizeString } from 'utils/helpers';
import moment from 'moment';

// Action Types
export const GET_HISTORY = 'logs/GET_HISTORY';
export const UPDATE_SEARCH = 'logs/UPDATE_SEARCH';
export const UPDATE_SEARCH_ITEMS = 'logs/UPDATE_SEARCH_ITEMS';
export const UPDATE_SELECTED_TAB = 'logs/UPDATE_SELECTED_TAB';
export const UPDATE_DATE_RANGE = 'logs/UPDATE_DATE_RANGE';
export const UPDATE_FILTERED_ITEMS = 'logs/UPDATE_FILTERED_ITEMS';
export const UPDATE_SELECTED_USER_ID = 'logs/UPDATE_SELECTED_USER_ID';
export const UPDATE_ERROR = 'logs/UPDATE_ERROR';

const REQUEST_LIMIT = 100
// Reducer
const initialState = {
  search: '',
  items: [],
  filteredItems: [],
  dateRange: undefined,
  selectedUserId: '',
  itemsNumber: '',
  error: null,
};

export default function reducer(state = initialState, action) {
  return produce(state, (draft) => {
    // eslint-disable-next-line default-case
    switch (action.type) {
      case GET_HISTORY:
        draft.items = action.history;
        draft.itemsNumber = action.itemsNumber;
        break;
      case UPDATE_SEARCH:
        draft.search = action.search;
        break;
      case UPDATE_FILTERED_ITEMS:
        draft.filteredItems = action.filteredItems;
        break;
      case UPDATE_DATE_RANGE:
        draft.dateRange = action.dateRange;
        break;
      case UPDATE_SELECTED_USER_ID:
        draft.selectedUserId = action.selectedUserId;
        break;
      case UPDATE_ERROR:
        draft.error = action.error;
        break;
    }
  });
}

// Actions Creators
function updateFilteredItemsAction(filteredItems) {
  return {
    type: UPDATE_FILTERED_ITEMS,
    filteredItems,
  };
}

function updateDateRangeAction(dateRange) {
  return {
    type: UPDATE_DATE_RANGE,
    dateRange,
  };
}

function updateSelectedUserIdAction(selectedUserId) {
  return {
    type: UPDATE_SELECTED_USER_ID,
    selectedUserId,
  };
}

function getHistoryAction(history, itemsNumber) {
  return {
    type: GET_HISTORY,
    history,
    itemsNumber,
  };
}

// Functions
function updateFilteredItems() {
  return (dispatch, getState) => {
    const { logs } = getState();
    const {
      search, dateRange, selectedUserId, items,
    } = logs;

    let filteredItems = items;

    if (search !== '') {
      filteredItems = filteredItems.filter((element) => {
        let value = '';
        if (element.new_gaia_object !== null) {
          value = element.new_gaia_object.SF_nomVoie || element.new_gaia_object.label
         || element.new_gaia_object.RA_libelle;
        } else if (element.old_gaia_object !== null) {
          value = element.old_gaia_object.RA_libelle;
        }
        if (!value) {
          return false;
        }
        return normalizeString(value).includes(normalizeString(search));
      });
    }
    if (dateRange) {
      filteredItems = filteredItems.filter(
        (item) => moment(item.datetime).isBetween(moment(dateRange[0]), moment(dateRange[1])),
      );
    }

    if (selectedUserId !== '') {
      if (!selectedUserId.includes('default')) {
        filteredItems = filteredItems.filter((element) => element.user === selectedUserId);
      }
    }

    dispatch(updateFilteredItemsAction(filteredItems));
  };
}

export function getHistory(onlyOwn = false, defaultParams = { limit: REQUEST_LIMIT }) {
  return async (dispatch, getState) => {
    const { user: { account } } = getState();

    const params = onlyOwn ? { ...defaultParams, user: account.id } : defaultParams;

    try {
      const res = await get('/midi/modification/', params);
      dispatch(getHistoryAction(res.results, res.count));
      dispatch(updateFilteredItems());
    } catch (e) {
      console.log(e);
    }
  };
}

export function updateSearch(search) {
  return (dispatch) => {
    dispatch({
      type: UPDATE_SEARCH,
      search,
    });
    dispatch(updateFilteredItems());
  };
}

export function updateSearchItems(items) {
  return (dispatch) => {
    dispatch({
      type: UPDATE_SEARCH_ITEMS,
      items,
    });
  };
}

export function updateDateRange(dateRange) {
  return (dispatch) => {
    dispatch(updateDateRangeAction(dateRange));
    dispatch(updateFilteredItems());
  };
}

export function updateSelectedUserId(selectedUserId) {
  return (dispatch) => {
    dispatch(updateSelectedUserIdAction(selectedUserId));
    dispatch(updateFilteredItems());
  };
}

export function updateError(error, errorMessage) {
  console.log(errorMessage);
  if (error !== null) { console.log(error.response.status, error.response.data); }
  return (dispatch) => {
    dispatch({
      type: UPDATE_ERROR,
      error,
    });
  };
}

export function restoreObject(modification) {
  return async (dispatch) => {
    try {
      const restoredObject = await post('/midi/modification/', modification);
    } catch (err) {
      dispatch(updateError(err, 'Object restoration Error'));
      return err;
    }
  };
}
