import React from 'react';
import { useSelector } from 'react-redux';
import { Source, Layer } from 'react-map-gl';

import { MAP_TRACK_SOURCES, MAP_URL } from 'common/Map/const';
import { trackNameLayer, lineNumberLayer } from 'common/Map/Layers/commonlayers.json';
import { schematicMainLayer, schematicServiceLayer } from 'common/Map/Layers/schematiclayers.json';
import { booleanToStringLayerVisibility } from 'utils/helpers';
import Gares from 'common/Map/Layers/Gares';

const SchematicView = () => {
  const { layers } = useSelector((state) => state.map);

  return (
    <>
      <Source type="vector" url={`${MAP_URL}/map/layer_def/gaia/map_dgs_schematique/sch/`} source-layer={MAP_TRACK_SOURCES.schematic}>
        <Layer
          {...schematicServiceLayer}
          layout={{ visibility: booleanToStringLayerVisibility(layers.vs.visible) }}
          source-layer={MAP_TRACK_SOURCES.schematic}
        />
        <Layer
          {...schematicMainLayer}
          layout={{ visibility: booleanToStringLayerVisibility(layers.vp.visible) }}
          source-layer={MAP_TRACK_SOURCES.schematic}
        />
        <Layer
          {...{
            ...trackNameLayer,
            layout: {
              ...trackNameLayer.layout,
              'text-field': '{SF_nomVoie}',
              'text-size': 16,
              visibility: booleanToStringLayerVisibility(layers.vp.visible),
            },
          }}
          source-layer={MAP_TRACK_SOURCES.schematic}
          filter={['==', 'SF_typeVoie', 'VP']}
        />
        <Layer
          {...{
            ...trackNameLayer,
            layout: {
              ...trackNameLayer.layout,
              'text-field': '{SF_nomVoie}',
              'text-size': 10,
              visibility: booleanToStringLayerVisibility(layers.vs.visible),
            },
          }}
          source-layer={MAP_TRACK_SOURCES.schematic}
          filter={['!=', 'SF_typeVoie', 'VP']}
        />
        <Layer
          {...{
            ...lineNumberLayer,
            layout: {
              ...lineNumberLayer.layout,
              'text-field': '{SF_codeLigne}',
              visibility: booleanToStringLayerVisibility(layers.vp.visible),
            },
          }}
          source-layer={MAP_TRACK_SOURCES.schematic}
        />
      </Source>


      <Gares mapURL={MAP_URL} sourceLayer="sch" />
    </>
  );
};

export default SchematicView;
