/* eslint-disable default-case */
import produce from 'immer';
import { get, deleteRequest, post } from 'common/requests';

// Action Types
export const UPDATE_SEARCH = 'library/UPDATE_SEARCH';
export const UPDATE_SEARCH_ITEMS = 'library/UPDATE_SEARCH_ITEMS';
export const DELETE_FAVORITE = 'library/DELETE_FAVORITE';
export const UPDATE_FAVORITES = 'library/UPDATE_FAVORITES';
export const UPDATE_ERROR = 'library/UPDATE_ERROR';

// Reducer
const initialState = {
  search: '',
  favorites: [],
  items: [],
  error: null,
};

export default function reducer(state = initialState, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case DELETE_FAVORITE:
        draft.favorites = draft.favorites.filter((fav) => fav.id !== action.favoriteId);
        break;
      case UPDATE_SEARCH:
        draft.search = action.search;
        break;
      case UPDATE_FAVORITES:
        draft.favorites = action.favorites;
        break;
      case UPDATE_SEARCH_ITEMS:
        draft.items = action.items;
        break;
      case UPDATE_ERROR:
        draft.error = action.error;
        break;
    }
  });
}

// Action Creators
function deleteFavoriteAction(favoriteId) {
  return {
    type: DELETE_FAVORITE,
    favoriteId,
  };
}

export function updateFavoritesAction(favorites) {
  return {
    type: UPDATE_FAVORITES,
    favorites,
  };
}

// Functions
export function updateSearchAction(search) {
  return async (dispatch) => {
    dispatch({
      type: UPDATE_SEARCH,
      search,
    });
  };
}

export function updateItemsAction(items) {
  return {
    type: UPDATE_SEARCH_ITEMS,
    items,
  };
}

// Functions
export function updateSearch(search) {
  return (dispatch) => {
    dispatch(updateSearchAction(search));
  };
}

export function updateItems(search) {
  return async (dispatch) => {
    try {
      const { results: items } = await get('/midi/document', { name: search });
      dispatch(updateItemsAction(items));
    } catch (e) {
      console.log(e);
    }
  };
}

export function getFavorites() {
  return async (dispatch) => {
    try {
      const { results: favorites } = await get('/midi/favouritedocument');
      dispatch(updateFavoritesAction(favorites));
    } catch (err) {
      if (err.response) {
        console.log(err.response.status, err.response.data);
      } else {
        console.log(err);
      }
    }
  };
}

export function toggleFavorites(itemId) {
  return async (dispatch, getState) => {
    const { library } = getState();
    const favorite = library.favorites.find((el) => el.document.id === itemId);

    try {
      if (favorite !== undefined) {
        await deleteRequest(`/midi/favouritedocument/${favorite.id}`);
        dispatch(deleteFavoriteAction(favorite.id));
      } else {
        await post('/midi/favouritedocument/', { document_id: itemId });
        dispatch(getFavorites());
      }
    } catch (err) {
      console.log(err);
    }
  };
}

export function updateError(error, errorMessage) {
  console.log(errorMessage);
  if (error !== null) { console.log(error.response.status, error.response.data); }
  return (dispatch) => {
    dispatch({
      type: UPDATE_ERROR,
      error,
    });
  };
}

export const postDocument = (form) => async () => {
  try {
    const document = await post('/midi/document/add', form);
    return document;
  } catch (error) {
    updateError(error, 'upload Error');
    return error;
  }
};
