/* eslint-disable jsx-a11y/interactive-supports-focus */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { DATA_TYPES } from 'common/types';
import { QUALITY_STATUS } from 'common/status';
import { getDeepObjectData } from 'utils/helpers';
import { ACTION_TYPES } from 'common/actionTypes';
import { CSSTransition } from 'react-transition-group';
import Loader from 'common/Loader';
import './common.scss';

class RawTableSNCF extends Component {
    static propTypes = {
      heads: PropTypes.array.isRequired,
      content: PropTypes.array.isRequired,
      t: PropTypes.func.isRequired,
      actions: PropTypes.func,
      fixedActions: PropTypes.bool,
      modalId: PropTypes.string,
      itemsList: PropTypes.array,
      cssExtra: PropTypes.string,
      showBody: PropTypes.bool,
    }

    static defaultProps = {
      actions: undefined,
      modalId: '',
      itemsList: [],
      fixedActions: true,
      cssExtra: '',
      showBody: true,
    }

    getData = (head, item) => {
      const { t, itemsList } = this.props;
      let cellContent = getDeepObjectData(item, head, itemsList);
      // Format status
      if (head.data[0].type === DATA_TYPES.status) {
        cellContent = t(QUALITY_STATUS[item.status].label);
      }
      // Format actions types
      if (head.data[0].type === DATA_TYPES.action) {
        cellContent = t(ACTION_TYPES[item.action_type].label);
      }
      return cellContent;
    }

    renderLine = (item) => {
      const {
        heads, actions, modalId, fixedActions,
      } = this.props;

      const cellFixedClass = fixedActions ? 'cell-fixed small-cell-sidebar' : '';

      return (
        <>
          {heads.map((head) => (
            <td key={head.key}>
              {head.onClick && (
                // eslint-disable-next-line jsx-a11y/click-events-have-key-events
                <div
                  className="cell-inner clickable"
                  data-target={head.key === 'supervisor_id' || head.key === 'owner_id' || head.key === 'groups' ? `#${modalId}` : ''}
                  data-toggle={head.key === 'supervisor_id' || head.key === 'owner_id' || head.key === 'groups' ? 'modal' : ''}
                  onClick={() => head.onClick(item)}
                  role="button"
                >
                  {this.getData(head, item)}
                </div>
              )}
              {!head.onClick && (
                <div className="cell-inner">
                  {this.getData(head, item)}
                </div>
              )}
            </td>
          ))}
          {actions && (
            <td className={cellFixedClass}>{actions(item.id, item.status, item.layer_name, item.action_type, item.type, item?.properties?.hasFeedback, item?.properties?.prediction?.id)}</td>
          )}
        </>
      );
    }

    render() {
      const {
        heads, content, t, actions, fixedActions, cssExtra, showBody,
      } = this.props;


      const cellFixedClass = fixedActions ? 'cell-fixed' : '';

      return (
        <div className="col-12 mt-3">
          <div className="table-wrapper">
            <div className={`table-scroller last-cell-fixed dragscroll ${cssExtra}`}>
            {!showBody && (<Loader></Loader>)}
              <table className="table table-hover">           
                <thead className="thead thead-light">
                  <tr>
                    {heads.map((head) => (<th scope="col" key={head.key}><div className="cell-inner">{t(head.label)}</div></th>))}
                    {actions && (<th className={cellFixedClass}><span className="sr-only">Actions</span></th>)}
                  </tr>
                </thead>
                <CSSTransition
                  in={showBody}
                  timeout={250}
                  classNames="alert"
                  unmountOnExit
                >
                  <tbody>
                    {content.map((item) => (
                      <tr key={item.id} id={item.id} className={item.has_been_restored ? 'table-success' : ''}>
                        {this.renderLine(item)}
                      </tr>
                    ))}
                  </tbody>
                </CSSTransition>

              </table>
            </div>
          </div>
        </div>
      );
    }
}

const TableSNCF = withTranslation()(RawTableSNCF);

export default TableSNCF;
