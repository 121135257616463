import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import * as allMapActions from 'reducers/map';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { Switch, Route, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';

import MastNavSNCF from 'common/BootstrapSNCF/MastNavSNCF';
import MastNavItemSNCF from 'common/BootstrapSNCF/MastNavItemSNCF';
import NavBarSNCF from 'common/BootstrapSNCF/NavBarSNCF';

import logo from 'assets/logo_sncf_reseau_web.svg';
import { DATA_PATH, MAP_PATH } from 'applications/phrit/routePaths';
import Map from 'applications/phrit/views/Map';
import Data from 'applications/phrit/views/Data/Data';

export class Home extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    mapActions: PropTypes.object.isRequired,
  }

  componentDidMount() {
    document.title = 'SNCF RÉSEAU - PHRIT';
  }

  clearWorkZone = () => {
    const { mapActions } = this.props;
    mapActions.updateSelectedZone();
  }

  render() {
    const { t } = this.props;
    return (
      <>
        <MastNavSNCF
          items={(
            <>
              <MastNavItemSNCF link={MAP_PATH} linkname={t('nav.map')} icon="icons-localisation-pin" />
              <MastNavItemSNCF link={DATA_PATH} linkname={t('nav.events')} icon="icons-large-warning" />
            </>
          )}
        />
        <NavBarSNCF appName="PHRIT" logo={logo} />
        <Switch>
          <Route exact path={MAP_PATH}>
            <Map />
          </Route>
          <Route path={`${MAP_PATH}/:lat/:lon/:zoom`}>
            <Map />
          </Route>
          <Route path={DATA_PATH}>
            <Data />
          </Route>
          <Redirect to={MAP_PATH} />
        </Switch>
      </>
    );
  }
}

const HomePhrit = withTranslation()(Home);

const mapStateToProps = (state) => ({
  map: state.map,
});

const mapDispatchToProps = (dispatch) => ({
  mapActions: bindActionCreators(allMapActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(HomePhrit);
