/* eslint-disable default-case */
import produce from 'immer';
import {
  get, patch, post, deleteRequest,
} from 'common/requests';
import { filterBySearch } from 'utils/helpers';
import moment from 'moment';

// Action Types
export const GET_ZONES = 'zones/GET_ZONES';
export const UPDATE_DATE_RANGE = 'zones/UPDATE_DATE_RANGE';
export const UPDATE_ZONE = 'zones/UPDATE_ZONE';
export const UPDATE_SEARCH = 'zones/UPDATE_SEARCH';
export const UPDATE_SELECTED_STATUS = 'zones/UPDATE_SELECTED_STATUS';
export const UPDATE_FILTERED_ITEMS = 'zones/UPDATE_FILTERED_ITEMS';
export const DELETE_ZONE = 'zones/DELETE_ZONE';
export const UPDATE_ERROR = 'zones/UPDATE_ERROR';

// Reducer
const initialState = {
  search: '',
  items: [],
  filteredItems: [],
  dateRange: undefined,
  selectedStatus: '',
  error: null,
};

export default function reducer(state = initialState, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case GET_ZONES:
        draft.items = action.zones;
        draft.filteredItems = action.zones;
        break;
      case UPDATE_SEARCH:
        draft.search = action.search;
        break;
      case UPDATE_FILTERED_ITEMS:
        draft.filteredItems = action.filteredItems;
        break;
      case UPDATE_ZONE:
        draft.items = draft.items.map((item) => {
          if (item.id === action.zone.id) {
            return action.zone;
          }
          return item;
        });
        break;
      case UPDATE_DATE_RANGE:
        draft.dateRange = action.dateRange;
        break;
      case UPDATE_SELECTED_STATUS:
        draft.selectedStatus = action.selectedStatus;
        break;
      case DELETE_ZONE:
        draft.items = draft.items.filter((item) => item.id !== action.zoneId);
        break;
      case UPDATE_ERROR:
        draft.error = action.error;
        break;
    }
  });
}

// Actions creators
function getZonesAction(zones) {
  return {
    type: GET_ZONES,
    zones,
  };
}

function updateZoneAction(zone) {
  return {
    type: UPDATE_ZONE,
    zone,
  };
}

function updateSearchAction(search) {
  return {
    type: UPDATE_SEARCH,
    search,
  };
}

function updateFilteredItemsAction(filteredItems) {
  return {
    type: UPDATE_FILTERED_ITEMS,
    filteredItems,
  };
}

function updateDateRangeAction(dateRange) {
  return {
    type: UPDATE_DATE_RANGE,
    dateRange,
  };
}

function updateSelectedStatusAction(selectedStatus) {
  return {
    type: UPDATE_SELECTED_STATUS,
    selectedStatus,
  };
}

// Functions
function updateFilteredItems() {
  return (dispatch, getState) => {
    const { zones } = getState();
    const {
      search, dateRange, selectedStatus, items,
    } = zones;

    let filteredItems = items;

    if (search !== '') {
      filteredItems = filterBySearch(filteredItems, search);
    }
    if (dateRange) {
      filteredItems = filteredItems.filter(
        (item) => moment(item.created).isBetween(moment(dateRange[0]), moment(dateRange[1])),
      );
    }

    if (selectedStatus !== '') {
      console.log(selectedStatus);
      if (!selectedStatus.includes('default')) {
        filteredItems = filteredItems.filter((element) => element.status.toString() === selectedStatus);
      }
    }

    dispatch(updateFilteredItemsAction(filteredItems));
  };
}

export function getZones(onlyOwn = false) {
  return async (dispatch, getState) => {
    const { user: { account } } = getState();
    try {
      let { results: zones } = await get('/midi/workzone');
      if (onlyOwn) {
        zones = zones.filter((zone) => zone.owner_id === account.id || zone.supervisor_id === account.id);
      }
      dispatch(getZonesAction(zones));
      dispatch(updateFilteredItems());
      return zones;
    } catch (e) {
      console.log(e);
    }
  };
}

export function updateError(error, errorMessage) {
  console.log(errorMessage);
  if (error !== null) { console.log(error.response.status, error.response.data); }
  return (dispatch) => {
    dispatch({
      type: UPDATE_ERROR,
      error,
    });
  };
}

export function createZone(zone) {
  return async (dispatch) => {
    try {
      const createdZone = await post('/midi/workzone', zone);
      console.log(createdZone);
    } catch (err) {
      dispatch(updateError(err, 'Create Zone Error'));
      return err;
    }
  };
}

export function deleteZone(zoneId) {
  return async (dispatch) => {
    try {
      await deleteRequest(`/midi/workzone/${zoneId}`);
      dispatch({
        type: DELETE_ZONE,
        zoneId,
      });
      dispatch(updateFilteredItems());
    } catch (err) {
      dispatch(updateError(err, 'Delete Zone Error'));
    }
  };
}

export function updateSearch(search) {
  return (dispatch) => {
    dispatch(updateSearchAction(search));
    dispatch(updateFilteredItems());
  };
}

export function updateStatus(id, status) {
  return async (dispatch) => {
    try {
      const updatedZone = await patch(`/midi/workzone/${id}/`, { status });
      dispatch(updateZoneAction(updatedZone));
      dispatch(updateFilteredItems());
    } catch (err) {
      console.log(err.response.status, err.response.data);
      dispatch(updateError(err, 'Update Status Error'));
    }
  };
}

export function updateProperties(id, props) {
  return async (dispatch) => {
    try {
      const updatedZone = await patch(`/midi/workzone/${id}/`, props);
      console.log(updatedZone);
      dispatch(updateZoneAction(updatedZone));
      dispatch(updateFilteredItems());
    } catch (err) {
      dispatch(updateError(err), 'Update Properties Error');
      return err;
    }
  };
}

export function updateOwner(id, owner) {
  return async (dispatch) => {
    try {
      const updatedZone = await patch(`/midi/workzone/${id}/`, owner);
      dispatch(updateZoneAction(updatedZone));
      dispatch(updateFilteredItems());
    } catch (err) {
      dispatch(updateError(err, 'Update Owner Error'));
      return err;
    }
  };
}

export function updateSupervisor(id, supervisor) {
  return async (dispatch) => {
    try {
      const updatedZone = await patch(`/midi/workzone/${id}/`, supervisor);
      dispatch(updateZoneAction(updatedZone));
      dispatch(updateFilteredItems());
    } catch (err) {
      dispatch(updateError(err, 'Update Supervisor Error'));
      return err;
    }
  };
}

export function updateDateRange(dateRange = undefined) {
  return (dispatch) => {
    dispatch(updateDateRangeAction(dateRange));
    dispatch(updateFilteredItems());
  };
}

export function updateSelectedStatus(selectedStatus) {
  return (dispatch) => {
    dispatch(updateSelectedStatusAction(selectedStatus));
    dispatch(updateFilteredItems());
  };
}
