import React from 'react';
import { Source, Layer } from 'react-map-gl';
import PropTypes from 'prop-types';

import Hover from 'common/Map/Hover';
import { getPhritObjectByKey, PHRIT_OBJECTS_KEYS } from 'applications/phrit/consts/objects';

const INCIDENT_PROPS = getPhritObjectByKey(PHRIT_OBJECTS_KEYS.event);

// Supposed to change with dynamic values, waiting for the back-end
// That's why it's very similar to PR.js for the moment.
const DISPLAY_PROPS = {
  type: 'circle',
  paint: {
    'circle-color': '#cd0037',
    'circle-radius': 5,
  },
};

const Incident = (props) => {
  const { isVisible, items } = props;

  const sourceLayer = INCIDENT_PROPS.sourceTable;
  const layout = {
    visibility: isVisible ? 'visible' : 'none',
  };

  const data = {
    type: 'FeatureCollection',
    features: items,
  };

  return (
    <Source
      id={`${sourceLayer}-source`}
      type="geojson"
      data={data}
    >
      <Layer
        id={`${sourceLayer}-layer`}
        layout={layout}
        {...DISPLAY_PROPS}
      />
      <Hover sourceLayer={sourceLayer} type="circle" />
    </Source>
  );
};

Incident.propTypes = {
  isVisible: PropTypes.bool,
  items: PropTypes.array.isRequired,
};

Incident.defaultProps = {
  isVisible: false,
};

export default Incident;
