import React from 'react';
import PropTypes from 'prop-types';
import kmORm from 'common/distances';
import { useTranslation } from 'react-i18next';

const PopupLine = ({ label, value }) => (
  <div className="labelvalue">
    <span className="labelvalue-label">{label.toUpperCase()}</span>
    {value}
  </div>
);

PopupLine.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
};

const PopupInfosCustomTitle = ({
  nomVoie, codeLigne, longueur, pkSncfDe, pkSncfFi, libelle, pk,
}) => {
  const distance = kmORm(longueur);

  let title = '';
  if (libelle.length !== 0) {
    title = libelle;
  } else if (codeLigne.length !== 0) {
    title = `${codeLigne} ${nomVoie}`;
  }

  return (
    <>
      <strong className="mr-2">{title}</strong>
      {distance && (
        <small className="mr-2">
          {distance.value}
          <small className="text-uppercase">{distance.unit}</small>
        </small>
      )}
      <small>
        {pkSncfDe !== ''
        && (
          <>
            {pkSncfDe}
            <strong> / </strong>
            {pkSncfFi}
          </>
        )}
        {pk !== '' && (pk)}
      </small>
    </>
  );
};

PopupInfosCustomTitle.propTypes = {
  nomVoie: PropTypes.string,
  codeLigne: PropTypes.string,
  longueur: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  pkSncfDe: PropTypes.string,
  pkSncfFi: PropTypes.string,
  libelle: PropTypes.string,
  pk: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
};

PopupInfosCustomTitle.defaultProps = {
  nomVoie: '',
  codeLigne: '',
  longueur: '',
  pkSncfDe: '',
  pkSncfFi: '',
  libelle: '',
  pk: '',
};

const PopupInfosCustomContent = ({
  codeTiv, idGaia, id, predCategory, predTime, hideId, eventType, failingResource,
  failureType, lowerPredTime, midPredTime, upperPredTime,
}) => {
  const { t } = useTranslation();

  return (
    <>
      {codeTiv && <PopupLine label={t('Map.popupTitles.codeTiv')} value={codeTiv} />}
      {!hideId && idGaia && <PopupLine label={t('Map.popupTitles.idGaia')} value={idGaia} />}
      {!hideId && idGaia === undefined && id && <PopupLine label={t('Map.popupTitles.id')} value={id} />}
      {eventType && <PopupLine label={t('Map.popupTitles.eventType')} value={eventType} />}
      {failureType && <PopupLine label={t('Map.popupTitles.failureType')} value={failureType} />}
      {failingResource && <PopupLine label={t('Map.popupTitles.failingResource')} value={failingResource} />}
      {predCategory && <PopupLine label={t('Map.popupTitles.predCategory')} value={predCategory} />}
      {predTime && <PopupLine label={t('Map.popupTitles.predTime')} value={predTime} />}
      {lowerPredTime && <PopupLine label={t('Map.popupTitles.lowerPredTime')} value={lowerPredTime} />}
      {midPredTime && <PopupLine label={t('Map.popupTitles.midPredTime')} value={midPredTime} />}
      {upperPredTime && <PopupLine label={t('Map.popupTitles.upperPredTime')} value={upperPredTime} />}
    </>
  );
};

PopupInfosCustomContent.propTypes = {
  codeTiv: PropTypes.string,
  idGaia: PropTypes.string,
  id: PropTypes.number,
  predCategory: PropTypes.string,
  predTime: PropTypes.string,
  hideId: PropTypes.bool,
  eventType: PropTypes.string,
  failureType: PropTypes.string,
  failingResource: PropTypes.string,
  lowerPredTime: PropTypes.string,
  midPredTime: PropTypes.string,
  upperPredTime: PropTypes.string,
};
PopupInfosCustomContent.defaultProps = {
  codeTiv: undefined,
  idGaia: undefined,
  id: undefined,
  predCategory: undefined,
  predTime: undefined,
  hideId: false,
  eventType: undefined,
  failureType: undefined,
  failingResource: undefined,
  lowerPredTime: undefined,
  midPredTime: undefined,
  upperPredTime: undefined,
};

export {
  PopupInfosCustomContent,
  PopupInfosCustomTitle,
};
