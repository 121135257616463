import React, { useRef, useState, useEffect } from 'react';
import ReactMapGL from 'react-map-gl';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import nextId from 'react-id-generator';
import ActionBarItem, { BAR_ITEM_TYPES } from 'common/BootstrapSNCF/ActionBarSNCF/ActionBarItem';
import FloatingBar from 'common/FloatingBar/FloatingBar';
// Components
import { MAP_TRACK_SOURCES } from 'common/Map/const';
import ButtonFullscreen from 'common/ButtonFullscreen';
import Loader from 'common/Loader';

// Helpers
import { useParams } from 'react-router-dom';
import ButtonResetViewport from 'common/ButtonResetViewport';
import { PHRIT_OBJECTS } from 'applications/phrit/consts/objects';
import ElementsMenu from 'common/Map/ElementsMenu';

import { MAP_PATH } from 'applications/phrit/routePaths';
import FiltersMenu from 'applications/phrit/components/FiltersMenu';
import Popup from 'applications/phrit/components/Popup';
import GeographicView from 'applications/phrit/components/GeographicView';
import SchematicView from 'applications/phrit/components/SchematicView';
import SelectSNCF from 'common/BootstrapSNCF/SelectSNCF';


import './common.scss';

import {
  toggleTrackSource, updateElementsList,
  updateFeatureInfoHover, updateViewport, toggleFilters, toggleObjects, addSelectedElement,
} from 'reducers/map';
import {
  EVENT_PARAMS, getEICList, getEvents, getEventType, getFailureType, getFailingResource,
} from 'reducers/phrit/events';
import LineGeographicView from 'applications/phrit/components/LineGeographicView';

const INTERACTIVE_LAYER_IDS = {
  [MAP_TRACK_SOURCES.schematic]: ['schematicMainLayer', 'schematicServiceLayer'],
  [MAP_TRACK_SOURCES.geographic]: ['geoMainLayer', 'geoServiceLayer'],
  [MAP_TRACK_SOURCES.lineGeographic]: ['geoLineMainLayer'],
};

const INITIAL_SELECTED_PARAMS = {
  [EVENT_PARAMS.eventType]: undefined,
  [EVENT_PARAMS.failureType]: undefined,
  [EVENT_PARAMS.failingResource]: undefined,
  [EVENT_PARAMS.eic]: undefined,
};

const REQUEST_LIMIT = '';
const ALL_OPTION = {
  key: 'all',
  label: 'Tous',
};

const Map = () => {
  const { t } = useTranslation();
  const mapRef = useRef();
  const routeParams = useParams();
  const {
    items, eics, eventType, failureType, failingResource, totalItems
  } = useSelector((state) => state.phrit.events);
  const [selectedParams, setSelectedParams] = useState(INITIAL_SELECTED_PARAMS);
  const [isFlying, setIsFlying] = useState(false);
  const [viewport, setViewport] = useState({});
  const [featureInfoHover, setFeatureInfoHover] = useState();
  const eventTypeOptions = [ALL_OPTION, ...eventType];
  const failureTypeOptions = [ALL_OPTION, ...failureType];
  const failingResourceOptions = [ALL_OPTION, ...failingResource];
  const eicOptions = [ALL_OPTION, ...eics];
  const [offset] = useState({ limit: REQUEST_LIMIT, offset: 0 });
  const [isLoading, setIsLoading] = useState(false);

  const {
    elements, viewport: propsViewport, featureInfoHoverID, trackSource, mapStyle,
  } = useSelector((state) => state.map);
  const { fullscreen } = useSelector((state) => state.main);
  const dispatch = useDispatch();

  const onViewportChange = (vp) => {
    if (isFlying) {
      setViewport(vp);
    } else {
      dispatch(updateViewport(vp, MAP_PATH));
    }
  };

  useEffect(() => {
    (async () => {
      try {
        dispatch(getEventType());
        dispatch(getFailureType());
        dispatch(getFailingResource());
        dispatch(getEICList());
        await dispatch(toggleTrackSource(MAP_TRACK_SOURCES.lineGeographic));
        await dispatch(updateElementsList(PHRIT_OBJECTS));
        await dispatch(addSelectedElement(PHRIT_OBJECTS[1], 'objects'));
      } catch (e) {
        console.log(e);
      }
      if (routeParams && routeParams.lat && routeParams.lon && routeParams.zoom) {
        const latitude = Number(routeParams.lat);
        const longitude = Number(routeParams.lon);
        const zoom = Number(routeParams.zoom);
        const newViewport = {
          ...propsViewport,
          latitude,
          longitude,
          zoom,
        };
        onViewportChange(newViewport);
      }
    })();
  }, []);

  useEffect(() => {
    dispatch(getEvents(selectedParams, offset));
    const interval = setInterval(() => {
      dispatch(getEvents(selectedParams, offset));
    }, 60000);
    return () => clearInterval(interval);
  }, [selectedParams, offset]);

  const onFeatureHover = (e) => {
    if (e.features && e.features.length > 0) {
      if (e.features[0].properties.id) {
        dispatch(updateFeatureInfoHover(e.features[0].properties.id, e.features[0].source));
      } else if (e.features[0].properties.OP_id) {
        dispatch(updateFeatureInfoHover(e.features[0].properties.OP_id, e.features[0].source));
      }
      setFeatureInfoHover(e);
    } else if (featureInfoHoverID !== null) {
      dispatch(updateFeatureInfoHover(null, null));
      setFeatureInfoHover();
    }
  };

  const onSelectParam = (param, value) => {
    setSelectedParams({
      ...selectedParams,
      [param]: value,
    });
  };

  const updateLocalViewport = (vp, transitionDuration) => {
    setViewport(vp);
    setIsFlying(true);

    setTimeout(() => {
      // Prevent redux viewport to transition twice
      delete vp.transitionDuration;
      delete vp.transitionInterpolator;

      dispatch(updateViewport(vp, MAP_PATH));
      setIsFlying(false);
    }, transitionDuration);
  };

  const getCursor = () => (featureInfoHover !== undefined ? 'pointer' : 'default');
  const getInteractiveLayers = () => [...INTERACTIVE_LAYER_IDS[trackSource], ...elements.selected.objects.map((el) => `${el.sourceTable}-layer`)];
  const selectedViewport = isFlying ? viewport : propsViewport;

  /* if (isLoading) {
    return (
      <div style={{ height: '70vh' }}>
        <Loader center />
      </div>
    );
  } */
  return (
    <>
      <FloatingBar>
        <ActionBarItem
          type={BAR_ITEM_TYPES.custom}
          content={(
            <SelectSNCF
              id={`select-params-${nextId()}`}
              title={t('Data.events.eventTypeTitle')}
              options={eventTypeOptions}
              selectedValue={selectedParams[EVENT_PARAMS.eventType] || ALL_OPTION}
              onChange={(e) => onSelectParam(EVENT_PARAMS.eventType, e.currentTarget.value)}
              selectStyle="cell-inner pr-6"
              labelKey="label"
            />
          )}
          spacing
          left
        />
        <ActionBarItem
          type={BAR_ITEM_TYPES.custom}
          content={(
            <SelectSNCF
              id={`select-params-${nextId()}`}
              title={t('Data.events.failureTypeTitle')}
              options={failureTypeOptions}
              selectedValue={selectedParams[EVENT_PARAMS.failureType] || ALL_OPTION}
              onChange={(e) => onSelectParam(EVENT_PARAMS.failureType, e.currentTarget.value)}
              selectStyle="cell-inner pr-6"
              labelKey="label"
            />
          )}
          spacing
          left
        />
        <ActionBarItem
          type={BAR_ITEM_TYPES.custom}
          content={(
            <SelectSNCF
              id={`select-params-${nextId()}`}
              title={t('Data.events.failingRessource')}
              options={failingResourceOptions}
              selectedValue={selectedParams[EVENT_PARAMS.failingResource] || ALL_OPTION}
              onChange={(e) => onSelectParam(EVENT_PARAMS.failingResource, e.currentTarget.value)}
              selectStyle="cell-inner pr-6"
              labelKey="label"
            />
          )}
          spacing
          left
        />
        <ActionBarItem
          type={BAR_ITEM_TYPES.custom}
          content={(
            <SelectSNCF
              id={`select-params-${nextId()}`}
              title={t('Data.events.eic')}
              options={eicOptions}
              selectedValue={selectedParams[EVENT_PARAMS.eic] || ALL_OPTION}
              onChange={(e) => onSelectParam(EVENT_PARAMS.eic, e.currentTarget.value)}
              selectStyle="cell-inner pr-6"
              labelKey="label"
            />
          )}
          spacing
          left
        />
        {/* <ActionBarItem
          type={BAR_ITEM_TYPES.custom}
          content={(
            <SelectSNCF
              id={`select-params-${nextId()}`}
              title="Tranche horaire"
              options={timeOptions}
              selectedValue={selectedParams[EVENT_PARAMS.eic]}
              onChange={(e) => onSelectParam(EVENT_PARAMS.eic, e.currentTarget.value)}
              selectStyle="cell-inner"
              labelKey="label"
            />
              )}
          spacing
          left
        /> */}
        <ActionBarItem
          type={BAR_ITEM_TYPES.iconButton}
          content="Filters"
          onClick={() => dispatch(toggleFilters())}
          iconName="icons-filters"
        />
        <ActionBarItem
          type={BAR_ITEM_TYPES.iconButton}
          content="Objects"
          onClick={() => dispatch(toggleObjects())}
          iconName="icons-list"
        />
      </FloatingBar>
      <main className={`mastcontainer mastcontainer-map${fullscreen ? ' fullscreen' : ''}`}>


        <FiltersMenu />
        <ElementsMenu />
        {totalItems === 0
          && (
            <div className="alert-no-results">
              <h2 className="text-white mb-0">{t('Data.map.alertNoResult')}</h2>
            </div>
          )}
        <ReactMapGL
          {...selectedViewport}
          style={{ cursor: 'pointer' }}
          width="100%"
          height="100%"
          mapStyle={mapStyle}
          onViewportChange={onViewportChange}
          ref={mapRef} // Needed to access the mapbox instance
          onClick={onFeatureHover}
          clickRadius={2} // Click made easier !
          interactiveLayerIds={getInteractiveLayers()} // Needed to allow getCursor & interactivity
          preventStyleDiffing
        >
          {trackSource === MAP_TRACK_SOURCES.geographic ? <GeographicView /> : null}
          {trackSource === MAP_TRACK_SOURCES.schematic ? <SchematicView /> : null}
          {trackSource === MAP_TRACK_SOURCES.lineGeographic ? <LineGeographicView /> : null}
          <Popup featureInfoHover={featureInfoHover} />
        </ReactMapGL>
        <ButtonResetViewport updateLocalViewport={updateLocalViewport} />
        <ButtonFullscreen />
      </main>
    </>
  );
};

export default Map;
