import React from 'react';
import { useSelector } from 'react-redux';

import { PHRIT_OBJECTS_KEYS } from 'applications/phrit/consts/objects';
import Incident from 'applications/phrit/components/Incident';
import PR from 'applications/phrit/components/PR';

const SelectedObjects = () => {
  const { elements } = useSelector((state) => state.map);
  const { items } = useSelector((state) => state.phrit.events);

  return elements.all.objects.map((obj) => {
    const isVisible = elements.selected.objects.findIndex((sel) => sel.key === obj.key) !== -1;
    switch (obj.key) {
      case PHRIT_OBJECTS_KEYS.pr:
        return <PR key={obj.key} isVisible={isVisible} />;
      case PHRIT_OBJECTS_KEYS.event:
        return <Incident key={obj.key} isVisible={isVisible} items={items} />;
      default:
        return null;
    }
  });
};

export default SelectedObjects;
