import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import StarRatings from 'react-star-ratings';
import moment from 'moment';
import 'moment/locale/fr';

import DatePickerSNCF, { PICKER_MODES } from 'common/BootstrapSNCF/DatePickerSNCF/DatePickerSNCF';
import ModalSNCF from 'common/BootstrapSNCF/ModalSNCF/ModalSNCF';
import ModalHeaderSNCF from 'common/BootstrapSNCF/ModalSNCF/ModalHeaderSNCF';
import ModalBodySNCF from 'common/BootstrapSNCF/ModalSNCF/ModalBodySNCF';
import ModalFooterSNCF from 'common/BootstrapSNCF/ModalSNCF/ModalFooterSNCF';
import ErrorBoundary from 'common/ErrorBoundaries/ErrorBoundary';

import { getFeedback, updateFeedback, updateSelectedEventId, getEventsTable } from 'reducers/phrit/events';

const FeedbackModal = ({ modalId, selectedParams, offset }) => {
  const { t } = useTranslation();

  const [rating, setRating] = useState(0);
  const [endDate, setEndDate] = useState(moment().toISOString());
  const [comment, setComment] = useState('');
  const { username } = useSelector((state) => state.user);
  const { selectedEventId, selectedPredictionId} = useSelector((state) => state.phrit.events);
  const dispatch = useDispatch();

  useEffect(() => {
    (async () => {
      if (selectedPredictionId) {
        const { results } = await dispatch(getFeedback(selectedPredictionId));
        if (results.length > 0) {
          const {
            comment: formerComment, review, date_heure_retablissement: formerEndDate,
          } = results[results.length - 1];
          setComment(formerComment);
          setRating(review);
          setEndDate(moment(formerEndDate).toISOString());
        } else {
          setComment('')
          setRating(0);
          setEndDate('');
        }
      }
    })();
  }, [selectedPredictionId]);

  function sendFeedback() {
    const feedback = {
      user: username,
      comment,
      review: rating,
      date_heure_retablissement: moment(endDate).toISOString(),
      incident_prediction: selectedPredictionId,
    };
    dispatch(updateFeedback(feedback));
    dispatch(getEventsTable(selectedParams, offset));
  };

  function closeModal() {
    setTimeout(() => dispatch(updateSelectedEventId()), 300);
  }

  const modalHeader = (
    <div>
      <strong>{`Incident n°${selectedEventId}`}</strong>
    </div>
  );

  const modalBody = (
    <>
      <div>
        <DatePickerSNCF
          htmlId="end-date-time"
          mode={PICKER_MODES.single}
          date={endDate}
          onChange={(date) => setEndDate(date[0])}
          onClear={setEndDate}
          placeholder="Date de fin"
          dateFormat="d/m/Y H:i"
          label="HRE réelle"
          enableTime
        />
      </div>
      <div>
        <h4 className="mt-5">Note de la prédiction</h4>
      </div>
      <StarRatings
        rating={rating}
        starDimension="40px"
        starSpacing="15px"
        changeRating={setRating}
        starHoverColor="#FFB612"
        starRatedColor="#FFB612"
      />
      <div>
        <h4 className="mt-5">Commentaire éventuel</h4>
        <textarea
          className="form-control"
          id="feedback-comment"
          placeholder="Votre commentaire"
          data-role="input"
          value={comment}
          onChange={(e) => setComment(e.target.value)}
        />
      </div>
    </>
  );
  const modalFooter = (
    <ErrorBoundary>
      <div className="d-flex align-items-end justify-content-end" style={{ width: '420px' }}>
        <div className="btn-group dropdown">
          <button
            type="button"
            className="btn btn-sm btn-secondary mr-2"
            data-dismiss="modal"
            onClick={closeModal}
          >
            <span>{t('Logs.zones.cancel')}</span>
          </button>
        </div>
        <div className="btn-group dropdown">
          <button
            disabled={!rating || !endDate}
            data-dismiss="modal"
            type="button"
            className="btn btn-sm btn-primary"
            onClick={sendFeedback}
          >
            <span>{t('Logs.zones.validate')}</span>
          </button>
        </div>
      </div>
    </ErrorBoundary>
  );

  return (
    <ModalSNCF htmlID={modalId}>
      <ModalHeaderSNCF>
        {modalHeader}
      </ModalHeaderSNCF>
      <ModalBodySNCF>
        {modalBody}
      </ModalBodySNCF>
      <ModalFooterSNCF>
        {modalFooter}
      </ModalFooterSNCF>
    </ModalSNCF>
  );
};

FeedbackModal.propTypes = {
  modalId: PropTypes.string,
};

FeedbackModal.defaultProps = {
  modalId: 'feedback-modal',
};

export default FeedbackModal;
