import React from 'react';
import { useSelector } from 'react-redux';
import { Source, Layer } from 'react-map-gl';

import { MAP_TRACK_SOURCES, MAP_URL } from 'common/Map/const';
import { geoMainLayer, geoServiceLayer } from 'common/Map/Layers/geographiclayers.json';
import { trackNameLayer, lineNumberLayer } from 'common/Map/Layers/commonlayers.json';
import { booleanToStringLayerVisibility } from 'utils/helpers';
import Gares from 'common/Map/Layers/Gares';

const GeographicView = () => {
  const { layers } = useSelector((state) => state.map);

  return (
    <>
      <Source type="vector" url={`${MAP_URL}/map/layer_def/gaia/map_dgs_geographique/geo/`} source-layer={MAP_TRACK_SOURCES.geographic}>
        <Layer
          {...geoMainLayer}
          layout={{ visibility: booleanToStringLayerVisibility(layers.vp.visible) }}
          source-layer={MAP_TRACK_SOURCES.geographic}
        />
        <Layer
          {...geoServiceLayer}
          layout={{ visibility: booleanToStringLayerVisibility(layers.vs.visible) }}
          source-layer={MAP_TRACK_SOURCES.geographic}
        />
        <Layer
          {...{
            ...trackNameLayer,
            layout: {
              ...trackNameLayer.layout,
              'text-field': '{GF_nomVoie}',
              visibility: booleanToStringLayerVisibility(layers.vp.visible),
            },
          }}
          source-layer={MAP_TRACK_SOURCES.geographic}
          filter={['==', 'GF_typeVoie', 'VPL']}
        />
        <Layer
          {...{
            ...trackNameLayer,
            layout: {
              ...trackNameLayer.layout,
              'text-field': '{GF_nomVoie}',
              'text-size': 10,
              visibility: booleanToStringLayerVisibility(layers.vs.visible),
            },
          }}
          source-layer={MAP_TRACK_SOURCES.geographic}
          filter={['!=', 'GF_typeVoie', 'VPL']}
        />
        <Layer
          {...{
            ...lineNumberLayer,
            layout: {
              ...lineNumberLayer.layout,
              'text-field': '{GF_ligne}',
            },
          }}
          source-layer={MAP_TRACK_SOURCES.geographic}
        />
      </Source>

      <Gares mapURL={MAP_URL} sourceLayer="geo" />
    </>
  );
};

export default GeographicView;
