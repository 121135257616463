import React from 'react';
import { Source, Layer } from 'react-map-gl';

import { MAP_URL } from 'common/Map/const';
import Gares from 'common/Map/Layers/Gares';
import Hover from 'common/Map/Hover';
import { geoLineMainLayer } from 'common/Map/Layers/geographiclayers.json';


import SelectedObjects from 'applications/phrit/components/SelectedObjects';

const LineGeographicView = () => (
  <>
    <Source
      type="vector"
      url={`${MAP_URL}/chartis/layer/phrit_ligne/mvt/geo/`}
    >
      <Layer
        {...geoLineMainLayer}
        source-layer="phrit_ligne"
      />
      <Hover sourceLayer="phrit_ligne" />
    </Source>

    <SelectedObjects />

    <Gares mapURL={MAP_URL} sourceLayer="geo" minzoom={9} />
  </>
);

export default LineGeographicView;
