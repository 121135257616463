/* eslint-disable default-case */
import produce from 'immer';
import { get, post } from 'common/requests';

// Action Types
export const GET_EVENTS = 'phrit/events/GET_EVENTS';
export const GET_EVENTS_TABLE = 'phrit/events/GET_EVENTS_TABLE';
export const GET_PARAMS = 'phrit/events/GET_PARAMS';
export const GET_EVENT_TYPE = 'phrit/events/GET_EVENT_TYPE';
export const GET_FAILURE_TYPE = 'phrit/events/GET_FAILURE_TYPE';
export const GET_FAILING_RESSOURCES = 'phrit/events/GET_FAILING_RESSOURCES';
export const GET_CI_LIST = 'phrit/events/GET_CI_LIST';
export const GET_CH_LIST = 'phrit/events/GET_CH_LIST';
export const GET_EIC_LIST = 'phrit/events/GET_EIC_LIST';
export const UPDATE_FILTERED_ITEMS = 'phrit/events/UPDATE_FILTERED_ITEMS';
export const UPDATE_SELECTED_EVENT_ID = 'phrit/events/UPDATE_SELECTED_EVENT_ID';
export const UPDATE_SELECTED_PREDICTION_ID = 'phrit/events/UPDATE_SELECTED_PREDICTION_ID';

// Endpoints
const API_URLS = {
  events: '/phrit/events/',
  eventType: '/flux/typeincident/',
  failureType: '/flux/typedefaillance/',
  failingResource: '/flux/typeressource/',
  CIList: '/phrit/pointremarquable/ci/',
  CHList: '/phrit/pointremarquable/ch/',
  EICList: '/phrit/pointremarquable/eic/',
  params: '/phrit/values',
  feedbacks: '/phrit',
};

export const EVENT_PARAMS = {
  eventType: 'type_incident',
  failureType: 'type_defaillance',
  failingResource: 'ressource_defaillante',
  eic: 'eic',
  brehatId: 'id_brehat_jour',
};

// Reducer
const initialState = {
  search: '',
  totalItems: 1,
  items: [],
  filteredItems: [],
  dateRange: undefined,
  selectedStatus: '',
  eventType: ['Diffic de récept° gare ou triage-Circ fond de gare'],
  failureType: ['Diffic de récept° gare ou triage-Circ fond de gare'],
  failingResource: ['Diffic de récept° gare ou triage-Circ fond de gare'],
  selectedEventId: undefined,
  selectedPredictionId: undefined,
  eics: [],
  isLoading: false,
  itemsTable: [],
};

export default function reducer(state = initialState, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case GET_EVENTS:
        draft.items = action.events;
        draft.filteredItems = action.events;
        draft.totalItems = action.totalItems;
        break;
      case GET_EVENTS_TABLE:
        draft.itemsTable = action.eventsTable;
        draft.filteredItems = action.eventsTable;
        draft.totalItems = action.totalItems;
        break;
      case GET_EVENT_TYPE:
        draft.eventType = action.eventType;
        break;
      case GET_FAILURE_TYPE:
        draft.failureType = action.failureType;
        break;
      case GET_FAILING_RESSOURCES:
        draft.failingResource = action.failingResource;
        break;
      case GET_EIC_LIST:
        draft.eics = action.eics;
        break;
      case UPDATE_SELECTED_EVENT_ID:
        draft.selectedEventId = action.eventId;
        break;
        case UPDATE_SELECTED_PREDICTION_ID:
        draft.selectedPredictionId = action.predictionId;
        break;
      case UPDATE_FILTERED_ITEMS:
        draft.filteredItems = action.filteredItems;
        break;
    }
  });
}

// Functions
export const getEvents = (eventParams, pagination) => async (dispatch) => {
  try {
    const params = {
      limit: pagination.limit,
      offset: pagination.offset,
    };
    if (eventParams) {
      Object.entries(eventParams).forEach(([k, v]) => {
        try {
          JSON.parse(v);
        } catch (e) {
          params[k] = v;
        }
      });
    }
    const { results: { features: events }, count: totalItems } = await get(API_URLS.events, params);
    dispatch({
      type: GET_EVENTS,
      events,
      totalItems,
    });
    return events;
  } catch (e) {
    console.log(e);
    return undefined;
  }
};

export const getEventsTable = (eventParams, pagination) => async (dispatch) => {
  try {
    const params = {
      limit: pagination.limit,
      offset: pagination.offset,
    };
    if (eventParams) {
      Object.entries(eventParams).forEach(([k, v]) => {
        try {
          const parsedValue = JSON.parse(v);
          if (!isNaN(parsedValue)) {
            params[k] = parsedValue;
          }
        } catch (e) {
          params[k] = v;
        }
      });
    }
    const { results: { features: eventsTable }, count: totalItems } = await get(API_URLS.events, params);
    dispatch({
      type: GET_EVENTS_TABLE,
      eventsTable,
      totalItems,
    });
    return eventsTable;
  } catch (e) {
    console.log(e);
    return undefined;
  }
};

export const getEICList = () => async (dispatch) => {
  try {
    const { results: eics } = await get(API_URLS.EICList);
    dispatch({
      type: GET_EIC_LIST,
      eics,
    });
    return eics;
  } catch (e) {
    console.log(e);
    return undefined;
  }
};

export const getEventType = () => async (dispatch) => {
  try {
    const params = {
      type: 'type_incident',
    };
    const { results: eventType } = await get(API_URLS.params, params);
    dispatch({
      type: GET_EVENT_TYPE,
      eventType,
    });
    return eventType;
  } catch (e) {
    console.log(e);
    return undefined;
  }
};
export const getFailureType = () => async (dispatch) => {
  try {
    const params = {
      type: 'type_defaillance',
    };
    const { results: failureType } = await get(API_URLS.params, params);
    dispatch({
      type: GET_FAILURE_TYPE,
      failureType,
    });
    return failureType;
  } catch (e) {
    console.log(e);
    return undefined;
  }
};
export const getFailingResource = () => async (dispatch) => {
  try {
    const params = {
      type: 'ressource_defaillante',
    };
    const { results: failingResource } = await get(API_URLS.params, params);
    dispatch({
      type: GET_FAILING_RESSOURCES,
      failingResource,
    });
    return failingResource;
  } catch (e) {
    console.log(e);
    return undefined;
  }
};

export const getCIList = () => async (dispatch) => {
  try {
    const params = {
      limit: 5000,
    };
    const { results } = await get(API_URLS.CIList, params);
    dispatch({
      type: GET_CI_LIST,
      CIList: results,
    });
    return results;
  } catch (e) {
    console.log(e);
    return undefined;
  }
};


export const updateSelectedEventId = (eventId, predictionId) => (dispatch) => {
  dispatch({
    type: UPDATE_SELECTED_EVENT_ID,
    eventId,
  });
  dispatch({
    type: UPDATE_SELECTED_PREDICTION_ID,
    predictionId,
  });
};

export const getFeedback = (eventId) => async () => {
  try {
    const feedbackRes = await get(`${API_URLS.events}${eventId}/prediction/feedbacks`);
    return feedbackRes;
  } catch (e) {
    console.log(e);
    return undefined;
  }
};

export const updateFeedback = (feedback) => async () => {
  try {
    const feedbackRes = await post(`${API_URLS.events}${feedback.incident_prediction}/prediction/feedbacks`, feedback);
    return feedbackRes;
  } catch (e) {
    console.log(e);
    return undefined;
  }
};
