import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import * as allEventsActions from 'reducers/phrit/events';
import { bindActionCreators } from 'redux';
import Loader from 'common/Loader';

class RawStats extends Component {
  static propTypes = {
    filters: PropTypes.object,
  }

  static defaultProps ={
    filters: <></>,
  }

  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
    };
  }

  getActions = () => {}

  render() {
    const { filters } = this.props;
    const { isLoading } = this.state;

    if (isLoading) {
      return (
        <div style={{ height: '50vh' }}>
          <Loader center />
        </div>
      );
    }

    return (
      <>
        <div className="row my-3">
          {filters}
        </div>
        <div className="row" style={{ overflow: 'auto', maxHeight: '59vh' }}>
          <div>En construction...</div>
        </div>
      </>
    );
  }
}

const Stats = withTranslation()(RawStats);

const mapStateToProps = (state) => ({
  events: state.phrit.events,
});

const mapDispatchToProps = (dispatch) => ({
  eventsActions: bindActionCreators(allEventsActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Stats);
